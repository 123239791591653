import { useEffect, useState } from 'react';
import { Link, Redirect, useParams } from "react-router-dom";
import isStrongPassword from 'validator/es/lib/isStrongPassword';

import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";

import AlertError from "../Alerts/Error";
import AlertSuccess from "../Alerts/Success";

const PASSWORD_REQUIREMENTS = { 
  minLength: 8,
  minLowercase: 1,
  minUppercase: 1,
  minNumbers: 1,
  minSymbols: 1,
}


function Verify({ token }) {
  let params = useParams();

  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmShown, setConfirmShown] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");

  const handleSubmit = (evt) => {
    setSuccessText("");
    setErrorText("");
    setSubmitted(true);
    evt.preventDefault();
    if (password === confirm && isStrongPassword(password, PASSWORD_REQUIREMENTS)){
      fetch(`${process.env.REACT_APP_API_URL}/verify`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "token": params.token,
          "password": password
        })
      })
      .then(r => r.json().then(json => ({ status: r.status, ok: r.ok, body: json })))
      .then(response => {
        if (!response.ok) {
          let err = new Error(`HTTP status code: ${response.status}`);
          err.status = response.status
          throw err
        }
        return response.body
      })
      .then(() => {
        setPassword("");
        setConfirm("");
        setSuccessText("Your password has been changed");
      })
      .catch(() => {
        setErrorText("Error, please try again");
        setSubmitted(false);
      });
  
    }
    else {
      setErrorText("Invalid password");
      setSubmitted(false);
    }
  }

  useEffect(() => {
    document.title = 'MiningGrade - Verify';
  }, []);

  if (!!token) return <Redirect to='/' />

  return (
    <div>
      <h1 className="text-2xl font-semibold md:text-4xl">Enter a new password</h1>

      {!!errorText ? <AlertError text={errorText} /> : ""}

      {!!successText ? <AlertSuccess text={successText} /> : ""}

      <form className="mt-6 w-full md:w-96" onSubmit={handleSubmit}>
        <div className="relative">
          <label className="text-sm text-gray-600" htmlFor="password">
            New password
          </label>
          <input
            className="mt-1"
            id="password"
            name="password"
            type={passwordShown ? "text" : "password"}
            placeholder="New password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            />
          <div className="absolute bottom-4 right-5">
            {passwordShown ? (
              <EyeIcon className="h-4 w-4 hover:text-blue-500" onClick={() => setPasswordShown(false)} />              
            ) : (
              <EyeOffIcon className="h-4 w-4 text-slate-400 hover:text-blue-500" onClick={() => setPasswordShown(true)} />              
            )}
          </div>
        </div>
        <div className="relative mt-7">
          <label className="text-sm text-gray-600" htmlFor="password-repeat">
            Repeat new password
          </label>
          <input
            className="mt-1"
            id="password-repeat"
            name="password-repeat"
            type={confirmShown ? "text" : "password"}
            placeholder="Repeat new password"
            value={confirm}
            onChange={e => setConfirm(e.target.value)}
            />
          <div className="absolute bottom-4 right-5">
            {confirmShown ? (
              <EyeIcon className="h-4 w-4 hover:text-blue-500" onClick={() => setConfirmShown(false)} />              
            ) : (
              <EyeOffIcon className="h-4 w-4 text-slate-400 hover:text-blue-500" onClick={() => setConfirmShown(true)} />              
            )}
          </div>
        </div>
        <div className="mt-12">
          <button
            className="w-full rounded-full border border-blue-500 bg-blue-500 px-12 py-2.5 text-lg font-semibold text-white hover:bg-blue-600"
            type="submit"
            disabled={submitted}
          >
            Change password
          </button>
        </div>
        <div className="mt-10 text-center text-sm text-slate-400">
          Already have an account?
          <Link
            to="/signin"
            className="ml-4 font-semibold text-gray-600 hover:text-blue-500"
          >
            Sign In
          </Link>
        </div>
      </form>
    </div>
  );
}

export default Verify;
