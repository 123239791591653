import 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import Moment from 'react-moment';

import Subheader from './Subheader';

import { MESSAGE_TYPE_EXTEND, MESSAGE_TYPE_LATEST } from '../constants';
import { gaEvent, useIntersectionObserver, websocket } from '../helpers';

function Releases() {
  const bottom = useRef(null);

  const isBottomVisible = useIntersectionObserver(bottom, {threshold: 0}, false);

  const [ feedItems, setFeedItems ] = useState([]);
  const [ receivedItems, setReceivedItems ] = useState([]);
  const [ newItemsCount, setNewItemsCount ] = useState(0);
  const [ infinityReached, setInfinityReached ] = useState(false);

  const onOpen = () => {
    if (!feedItems.length) {
      websocket.send(JSON.stringify({
        "type": MESSAGE_TYPE_LATEST
      }));
    }
  }

  const onMessage = (event) => {
    const response = JSON.parse(event.data);
    if (response.type === MESSAGE_TYPE_LATEST){
      setFeedItems(prevState => {
        if (!prevState.length){
          return response.data;
        }
        else {
          setReceivedItems(response.data);
          return prevState;
        }  
      });
    }
    if (response.type === MESSAGE_TYPE_EXTEND){
      setFeedItems(prevState => {
        var ids = new Set(prevState.map(i => i.id));
        var newItems = response.data.filter(i => !ids.has(i.id));
        if (newItems.length){
          return [...prevState, ...newItems];
        }
        else {
          setInfinityReached(true);
          return prevState;
        }
      });
    }
  }

  const refreshFeedItems = () => {
    document.querySelector(`script[src="${process.env.REACT_APP_CDN_URL}/initial_payload.js"]`).remove();
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `${process.env.REACT_APP_CDN_URL}/initial_payload.js`;
    document.head.appendChild(script);    
    setFeedItems(receivedItems);
    window.scrollTo({top: 0, behavior: 'smooth'});
    gaEvent({
      action: "refresh_feed_items",
      params : {
        new_items: newItemsCount
      }
    });
    setNewItemsCount(0);
    setReceivedItems([]);
  }

  // Fetch initial payload and setup for websocket handlers
  useEffect(() => {
    document.title = 'MiningGrade';

    setFeedItems(window.initial_payload.data);

    if (!websocket.readyState){
      websocket.addEventListener('open', onOpen);
    }
    else {
      onOpen();
    }
    websocket.addEventListener('message', onMessage);

    return () => {
      websocket.removeEventListener('open', onOpen);
      websocket.removeEventListener('message', onMessage);
    };
  }, []);

  // Send websocket to get more feed items
  useEffect(() => {
    if (document.documentElement.scrollTop === 0 || !isBottomVisible) return;

    const oldest = feedItems[feedItems.length - 1];
    websocket.send(JSON.stringify({
      "type": MESSAGE_TYPE_EXTEND,
      "published": oldest.published
    }));
  }, [isBottomVisible]);

  // Calculate number of new items received from websocket message
  useEffect(() => {
    if (!receivedItems.length || newItemsCount > 0) return;

    let index = 0;
    let count = receivedItems.length;
    while (index < receivedItems.length) {
      if (receivedItems[index].id === feedItems[0].id){
        count = index;
        break;
      }
      index++;
    }
    if (count > 0){
      setNewItemsCount(count);
    }
    else {
      setReceivedItems([]);
    }
  }, [receivedItems]);

  return (
    <div>
      { newItemsCount > 0 && (
        <div className="text-center lg:pt-6 lg:px-4">
          <div className="py-4 px-8 bg-blue-100 items-center text-blue-500 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
            <span className="font-medium mr-2 flex-auto">{newItemsCount} new press releases <span className="underline cursor-pointer hover:text-gray-900" onClick={() => refreshFeedItems()}>refresh</span></span>
          </div>
        </div>
      )}
      
      <Subheader selected="releases" />

      <div className="mt-8 md:mt-14 px-4 sm:px-6 bg-white">
        <div className="max-w-screen-xl mx-auto">
          <div className="max-w-4xl">
            { feedItems && feedItems.map((item, index) => (
              <div key={index} className="border-b border-slate-300 mt-4 md:mt-8">
                <Link to={`/r/${item.id}/${item.slug}`} className="text-gray-900 hover:text-blue-500">
                  <h3 className="font-medium text-xl md:text-2xl" dangerouslySetInnerHTML={{__html: item.headline}}></h3>
                </Link>
                <div className="font-medium text-xs text-gray-900 mt-2 md:mt-5">
                  <span className="uppercase"><Moment date={item.published} format="MMM Do YYYY" tz="America/New_York" /> <Moment date={item.published} format="h:mm A z" tz="America/New_York" /></span>
                </div>
                <div className="font-medium text-xs text-gray-900 uppercase mt-2 md:mt-5 mb-3 md:mb-6">
                  {item.symbols.length && item.symbols.join(", ")}
                </div>
              </div>
            ))}
            { !infinityReached && (
              <div ref={bottom} className="mx-auto my-8 dot-flashing"></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Releases;
