import { useEffect } from 'react';
import { Redirect } from "react-router-dom";

import Companies from "./Companies";

function Admin({ tool, token, admin }) {
    useEffect(() => {
      document.title = 'MiningGrade - Admin';
    }, []);

    if (!token || !admin) return <Redirect to='/' />

    // TODO - Admin menu
    switch (tool) {
    //case "page":
    //  return <Page />;
      default:
      // TODO - Admin dashboard
      return <Companies token={token} />;
    }
}

export default Admin;