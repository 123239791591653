import { useState } from 'react';
import { BrowserRouter, Switch, Route} from "react-router-dom";

import Header from './components/Header';
import Releases from './components/Releases';
import Release from './components/Release';
import Account from './components/Account/Index';
import Trending from './components/Trending';
import Admin from './components/Admin/Index';


function App() {
  const [username, setUsername] = useState(localStorage.getItem('username'));
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [admin, setAdmin] = useState(localStorage.getItem('admin') === 'true');

  const signin = (username, token, admin) => {
    localStorage.setItem('username', username);
    localStorage.setItem('token', token);
    localStorage.setItem('admin', admin);
    setUsername(username);
    setToken(token);
    setAdmin(admin);
  }

  const signout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    localStorage.removeItem('admin');
    setUsername(null);
    setToken(null);
    setAdmin(null);
  }

  return (
    <BrowserRouter>
      <Header username={username} admin={admin} signout={signout} />
      <Switch>
        <Route path="/r/:id/:title">
          <Release />
        </Route>
        <Route path="/trending">
          <Trending />
        </Route>
        <Route path="/signup">
          <Account form="signup" token={token} />
        </Route>
        <Route path="/forgot">
          <Account form="forgot" token={token} />
        </Route>
        <Route path="/verify/:token">
          <Account form="verify" token={token} />
        </Route>
        <Route path="/signin">
          <Account form="signin" token={token} signin={signin} />
        </Route>
        <Route path="/admin">
          <Admin token={token} admin={admin} />
        </Route>
        <Route path="/">
          <Releases />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
