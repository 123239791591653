import { Link } from 'react-router-dom';

function Header({ username, admin, signout }) {
  return (
    <div className="relative bg-white">
      <div className="shadow-3xl flex items-center justify-between md:justify-start md:space-x-10">
        <div className="flex justify-start lg:w-0 lg:flex-1">
          <div className="border-r border-gray-200 py-4 px-4 sm:px-6">
            <Link to="/">
              <span className="sr-only">MiningGrade</span>
              <img
                className="h-8 w-auto sm:h-10"
                src="/img/logo.png"
                width="40"
                height="40"
                alt="MiningGrade"
              />
            </Link>
          </div>
          <div className="ml-6 flex items-center justify-end">
            <Link to="/" className="text-lg">
              MiningGrade
            </Link>
          </div>
        </div>
        <div className="hidden pr-4 sm:pr-6">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            aria-expanded="false"
          >
            <span className="sr-only">Open menu</span>
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <div className="hidden space-x-10 py-4 md:flex"></div>
        <div className="items-center justify-end py-4 pr-4 sm:pr-6 md:flex md:flex-1 lg:w-0">
          {!!username && (
            <div>{username}&nbsp;|&nbsp;</div>
          )}
          {!!username && !!admin && (
            <div><Link to="/admin">admin</Link>&nbsp;|&nbsp;</div>
          )}
          {!!username && (
            <div><a onClick={() => signout()}>signout</a></div>
          )}
          {!username && (
            <span>&nbsp;</span>
            // <Link
            //   to="/signin"
            //   className="font-medium- rounded-full border border-blue-500 bg-white px-8 py-2 text-sm text-blue-500 hover:bg-blue-500 hover:text-white"
            // >
            //   Sign In
            // </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
