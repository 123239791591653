import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import './styles/build.css';
import App from './App';

Sentry.init({
  dsn: "https://50a1260487974334a71539faeab6f1f2@o4504215874830336.ingest.sentry.io/4504218900234240",
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
