import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

const AlertError = ({ text }) => (
  <div className="mx-auto mt-6 flex w-full max-w-sm overflow-hidden rounded-xl border border-blue-500">
    <div className="flex w-12 items-center justify-center bg-blue-500">
      <ExclamationCircleIcon className="h-6 w-6 text-white" />
    </div>

    <div className="-mx-3 px-4 py-2">
      <div className="mx-3">
        <span className="font-semibold text-blue-500">Error</span>
        <p className="text-sm text-gray-600">{text}</p>
      </div>
    </div>
  </div>
);

export default AlertError;