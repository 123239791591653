import { useEffect } from 'react';

import Subheader from './Subheader';


function Trending() {
  useEffect(() => {
    document.title = 'MiningGrade - Trending';
  }, []);

  return (
    <div>
      <Subheader selected="trending" />

      <h1 className="mt-10 px-4 text-3xl font-bold">
        Coming Soon!
      </h1>
    </div>
  );
}

export default Trending;
