import React from "react";
import { Link } from "react-router-dom";

import Signin from "./Signin";
import Signup from "./Signup";
import Forgot from "./Forgot";
import Verify from "./Verify";

const Account = ({ form, token, signin }) => (
  <div
    className="flex min-h-screen justify-items-center bg-cover bg-center bg-no-repeat px-4 sm:px-6"
    style={{ backgroundImage: "url(/img/bg-signin.jpg)" }}
  >
    <div className="mx-auto w-full max-w-screen-xl py-12 md:py-20">
      <div className="flex h-full flex-col place-content-center items-center justify-items-center space-y-10 lg:flex-row lg:space-y-0 lg:space-x-12">
        <div className="w-full text-center">
          <Link to="/" className="inline-block">
            <span className="sr-only">MiningGrade</span>
            <img
              className="h-10 w-24 md:h-14 md:w-36"
              src="/img/logo.png"
              width="150"
              height="59"
              alt="MiningGrade"
            />
          </Link>
        </div>
        <div className="md:rounded-5xl rounded-3xl bg-white p-12 md:py-24 md:px-32">
          {(function () {
            switch (form) {
              case "forgot":
                return <Forgot token={token} />;
              case "verify":
                return <Verify token={token} />;
              case "signup":
                return <Signup token={token} />;
              default:
                return <Signin token={token} signin={signin} />;
            }
          })()}
        </div>
      </div>
    </div>
  </div>
);

export default Account;