import { useEffect, useState } from 'react';
import { Link, Redirect } from "react-router-dom";
import isEmail from 'validator/es/lib/isEmail';

import AlertError from "../Alerts/Error";
import AlertSuccess from "../Alerts/Success";

function Signup({ token }) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");

  const handleSubmit = (evt) => {
    setSuccessText("");
    setErrorText("");
    setSubmitted(true);
    evt.preventDefault();
    if (isEmail(email)){
      fetch(`${process.env.REACT_APP_API_URL}/signup`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "username": username,
          "email": email,
        })
      })
      .then(r => r.json().then(json => ({ status: r.status, ok: r.ok, body: json })))
      .then(response => {
        if (!response.ok) {
          let err = new Error(`HTTP status code: ${response.status}`);
          err.status = response.status
          throw err
        }
        return response.body
      })
      .then(() => {
        setUsername("");
        setEmail("");
        setSuccessText("Check your email for instructions on creating your password");
      })
      .catch(() => {
        setErrorText("Error, please try again");
        setSubmitted(false);
      });
    }
    else {
      setErrorText(`Invalid email ${email}`);
      setSubmitted(false);
    }
  }

  useEffect(() => {
    document.title = 'MiningGrade - Signup';
  }, []);

  if (!!token) return <Redirect to='/' />

  return (
    <div>
      <h1 className="text-2xl font-semibold md:text-4xl">Create account</h1>

      {!!errorText ? <AlertError text={errorText} /> : ""}

      {!!successText ? <AlertSuccess text={successText} /> : ""}

      <div className="mt-6 text-sm text-gray-600">
        Sign up for free real-time mining news from around the world
      </div>

      <form className="mt-6 w-full md:w-96" onSubmit={handleSubmit}>
        <div>
          <label className="text-sm text-gray-600" htmlFor="username">
            Username
          </label>
          <input
            className="mt-1"
            id="username"
            name="username"
            type="text"
            placeholder="Username"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </div>
        <div className="mt-7">
          <label className="text-sm text-gray-600" htmlFor="email">
            Email
          </label>
          <input
            className="mt-1"
            id="email"
            name="email"
            type="email"
            placeholder="Email address"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className="mt-7 text-xs text-gray-600">
          By clicking Sign Up, you agree to our &nbsp;
          <Link to="/terms" className="font-bold hover:text-blue-500">
            Terms
          </Link>
          &nbsp; and &nbsp;
          <Link to="/privacy" className="font-bold hover:text-blue-500">
            Privacy Policy
          </Link>
          .
        </div>
        <div className="mt-7">
          <button
            className="w-full rounded-full border border-blue-500 bg-blue-500 px-12 py-2.5 text-lg font-semibold text-white hover:bg-blue-600"
            type="submit"
            disabled={submitted}
          >
            Sign up
          </button>
        </div>
        <div className="mt-10 text-center text-sm text-slate-400">
          Already have an account?
          <Link
            to="/signin"
            className="ml-4 font-semibold text-gray-600 hover:text-blue-500"
          >
            Sign In
          </Link>
        </div>
      </form>
    </div>
  );
}

export default Signup;
