// import { Link } from 'react-router-dom';

function Subheader({ selected }) {
  return (
    <div className="mt-10 md:mt-8 px-4 sm:px-6">
      <div className="max-w-screen-xl mx-auto">
        <div className="border-b border-slate-300 mt-4 md:mt-8">
          <div className="mb-3 md:mb-6">
            <h4 className="font-light text-xl md:text-2xl">Real-time mining news without the ads</h4>
          </div>
          <div className="text-xs">Welcome to the Beta! We're building the fastest ad-free mining news site.</div>
          <div className="text-xs mb-3 md:mb-6">If you'd like to help or have some feedback on the site, reach out to us on <a className="font-bold underline hover:text-blue-500" href="https://twitter.com/mininggrade" target="_blank">Twitter</a> or email at <a className="font-bold underline hover:text-blue-500" href="mailto:hello@mininggrade.com">hello@mininggrade.com</a>.</div>
        </div>
        <div className="border-b border-slate-300 mt-4 md:mt-8">
          <div className="mb-3 md:mb-6">
            <h2 className="font-light text-2xl md:text-4xl">News Feed</h2>
          </div>
        </div>
      </div>
    </div>
  )
  // return (
  //   <div>
  //     <div className="mt-10 md:mt-8 px-4 sm:px-6">
  //       <div className="max-w-screen-xl mx-auto">
  //         <div className="flex space-x-4 md:space-x-6">
  //           <a href="#" className="text-sm font-medium leading-8 text-blue-500 hover:text-blue-500 relative after:absolute after:bg-blue-500 after:h-1 after:left-0 after:right-0 after:bottom-0 after:rounded-full hover:after:bg-blue-500">All</a>
  //           <a href="#" className="text-sm font-medium leading-8 text-gray-400 hover:text-blue-500 flex items-center relative after:absolute after:bg-transparent after:h-1 after:left-0 after:right-0 after:bottom-0 after:rounded-full hover:after:bg-blue-500">Americas</a>
  //           <a href="#" className="text-sm font-medium leading-8 text-gray-400 hover:text-blue-500 flex items-center relative after:absolute after:bg-transparent after:h-1 after:left-0 after:right-0 after:bottom-0 after:rounded-full hover:after:bg-blue-500">Europe</a>
  //           <a href="#" className="text-sm font-medium leading-8 text-gray-400 hover:text-blue-500 flex items-center relative after:absolute after:bg-transparent after:h-1 after:left-0 after:right-0 after:bottom-0 after:rounded-full hover:after:bg-blue-500">Asia</a>
  //         </div>
  //       </div>
  //     </div>

  //     <div className="mt-10 md:mt-8 px-4 sm:px-6">
  //       <div className="max-w-screen-xl mx-auto">
  //         <div className="flex space-x-4 md:space-x-12">
  //           { selected === "releases" ? (
  //             <span className="text-lg font-medium leading-8 text-blue-500 hover:text-blue-500 relative after:absolute after:bg-blue-500 after:h-1 after:left-0 after:right-0 after:bottom-0 after:rounded-full hover:after:bg-blue-500">Most Recent</span>
  //           ) : (
  //             <Link to="/" className="text-lg font-medium leading-8 text-gray-400 hover:text-blue-500 relative after:absolute after:bg-transparent after:h-1 after:left-0 after:right-0 after:bottom-0 after:rounded-full hover:after:bg-blue-500">Most Recent</Link>
  //           )}
  //           <a href="#" className="text-lg font-medium leading-8 text-gray-400 hover:text-blue-500 flex items-center relative after:absolute after:bg-transparent after:h-1 after:left-0 after:right-0 after:bottom-0 after:rounded-full hover:after:bg-blue-500">Following</a>
  //           { selected === "trending" ? (
  //             <span className="text-lg font-medium leading-8 text-blue-500 hover:text-blue-500 flex items-center relative after:absolute after:bg-blue-500 after:h-1 after:left-0 after:right-0 after:bottom-0 after:rounded-full hover:after:bg-blue-500">
  //               <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-500 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  //                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" /> 
  //               </svg> 
  //               Trending
  //             </span>
  //           ) : (
  //             <Link to="/trending" className="text-lg font-medium leading-8 text-gray-400 hover:text-blue-500 flex items-center relative after:absolute after:bg-transparent after:h-1 after:left-0 after:right-0 after:bottom-0 after:rounded-full hover:after:bg-blue-500">
  //               <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-500 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  //                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" /> 
  //               </svg> 
  //               Trending
  //             </Link>
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default Subheader;
