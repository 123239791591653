import { useEffect, useState } from 'react';
import { Link, Redirect } from "react-router-dom";
import isEmail from 'validator/es/lib/isEmail';

import AlertError from "../Alerts/Error";
import AlertSuccess from "../Alerts/Success";


function Signup({ token }) {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");
  
  const handleSubmit = (evt) => {
    setSuccessText("");
    setErrorText("");
    setSubmitted(true);
    evt.preventDefault();
    if (isEmail(email)){
      fetch(`${process.env.REACT_APP_API_URL}/signup`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "email": email
        })
      })
      .then(r => r.json().then(json => ({ status: r.status, ok: r.ok, body: json })))
      .then(response => {
        if (!response.ok) {
          let err = new Error(`HTTP status code: ${response.status}`);
          err.status = response.status
          throw err
        }
        return response.body
      })
      .then(() => {
        setEmail("");
        setSuccessText("Check your email for instructions on creating your password");
      })
      .catch(() => {
        setErrorText("Error, please try again");
        setSubmitted(false);
      });
    }
    else {
      setErrorText(`Invalid email ${email}`);
      setSubmitted(false);
    }
  }

  useEffect(() => {
    document.title = 'MiningGrade - Forgot';
  }, []);

  if (!!token) return <Redirect to='/' />

  return (
    <div>
      <h1 className="text-2xl font-semibold md:text-4xl">Forgot password</h1>

      {!!errorText ? <AlertError text={errorText} /> : ""}

      {!!successText ? <AlertSuccess text={successText} /> : ""}

      <div className="mt-10 space-y-3 text-sm text-gray-600">
        <p>
          Enter the email address you used when you joined and we'll send you
          instructions to reset your password.
        </p>
        <p>
          For security reasons, we do NOT store your password. So rest assured
          that we will never send your password via email.
        </p>
      </div>

      <form className="mt-10 w-full md:w-96" onSubmit={handleSubmit}>
        <div>
          <label className="text-sm text-gray-600" htmlFor="email">
            Email
          </label>
          <input
            className="mt-1"
            id="email"
            name="email"
            type="email"
            placeholder="Email address"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className="mt-12">
          <button
            className="w-full rounded-full border border-blue-500 bg-blue-500 px-12 py-2.5 text-lg font-semibold text-white hover:bg-blue-600"
            type="submit"
            disabled={submitted}
          >
            Send Reset Link
          </button>
        </div>
        <div className="mt-10 text-center text-sm text-slate-400">
          Already have an account?
          <Link
            to="/signin"
            className="ml-4 font-semibold text-gray-600 hover:text-blue-500"
          >
            Sign In
          </Link>
        </div>
      </form>
    </div>
  );
}

export default Signup;
