import React from "react";
import { CheckCircleIcon } from "@heroicons/react/outline";

const AlertError = ({ text }) => (
  <div className="mx-auto mt-6 flex w-full max-w-sm overflow-hidden rounded-xl border border-green-500">
    <div className="flex w-12 items-center justify-center bg-green-500">
      <CheckCircleIcon className="h-6 w-6 text-white" />
    </div>

    <div className="-mx-3 px-4 py-2">
      <div className="mx-3">
        <span className="font-semibold text-green-500">Success</span>
        <p className="text-sm text-gray-600">{text}</p>
      </div>
    </div>
  </div>
);

export default AlertError;