import { useState, useEffect } from 'react';

import AlertError from "../Alerts/Error";

function Companies({ token }) {
    const [companies, setCompanies] = useState([]);
    const [companiesSource, setCompaniesSource] = useState([]);
    const [company, setCompany] = useState("")
    const [source, setSource] = useState("")
    const [name, setName] = useState("");
    const [exchange, setExchange] = useState("");
    const [symbol, setSymbol] = useState("");
    const [errorText, setErrorText] = useState("");

    const getCompanies = () => {
        fetch(`${process.env.REACT_APP_API_URL}/admin/companies`, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': token
          }
        })
        .then(r => r.json().then(json => ({ status: r.status, ok: r.ok, body: json })))
        .then(response => {
          if (!response.ok) {
            let err = new Error(`HTTP status code: ${response.status}`);
            err.status = response.status
            throw err
          }
          return response.body
        })
        .then(json => {
          setCompanies(json.result.companies)
          setCompaniesSource(json.result.companies_source)
        })
        .catch(console.error);
    }

    const addCompany = () => {
        setErrorText("");
        fetch(`${process.env.REACT_APP_API_URL}/admin/companies`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-API-Key': token
            },
            body: JSON.stringify({
                "source_id": source,
                "name": name,
                "exchange": exchange,
                "symbol": symbol,
            })
        })
        .then(r => r.json().then(json => ({ status: r.status, ok: r.ok, body: json })))
        .then(response => {
            if (!response.ok) {
                let err = new Error(`HTTP status code: ${response.status}`);
                err.status = response.status
                throw err
            }
            return response.body
        })
        .then(json => {
            console.log(json);
            setCompanies([...companies, json.result.companies]);
            setCompaniesSource([...companiesSource.filter(c => c.id !== source), json.result.companies_source]);
            setSource("");
            setName("");
            setExchange("");
            setSymbol("");
        })
        .catch((error) => setErrorText(error.message));
    }

    const updateCompany = () => {
        setErrorText("");
        fetch(`${process.env.REACT_APP_API_URL}/admin/companies/${company}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'X-API-Key': token
            },
            body: JSON.stringify({
                "source_id": source,
            })
        })
        .then(r => r.json().then(json => ({ status: r.status, ok: r.ok, body: json })))
        .then(response => {
            if (!response.ok) {
                let err = new Error(`HTTP status code: ${response.status}`);
                err.status = response.status
                throw err
            }
            return response.body
        })
        .then(json => {
            console.log(json);
            setCompaniesSource([...companiesSource.filter(c => c.id !== source), json.result.companies_source]);
            setCompany("");
            setSource("");
            setName("");
            setExchange("");
            setSymbol("");
        })
        .catch((error) => setErrorText(error.message));
    }

    const ignoreCompany = () => {
        setErrorText("");
        fetch(`${process.env.REACT_APP_API_URL}/admin/companies/source/${source}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'X-API-Key': token
            }
        })
        .then(r => r.json().then(json => ({ status: r.status, ok: r.ok, body: json })))
        .then(response => {
            if (!response.ok) {
                let err = new Error(`HTTP status code: ${response.status}`);
                err.status = response.status
                throw err
            }
            return response.body
        })
        .then(json => {
            console.log(json);
            setCompaniesSource([...companiesSource.filter(c => c.id !== source), json.result.companies_source]);
            setSource("");
            setName("");
            setExchange("");
            setSymbol("");
        })
        .catch((error) => setErrorText(error.message));
    }

    useEffect(() => {
        if (!companiesSource.length) getCompanies();
    }, [companies]);

    const selectCompany = (id, name, exchange, symbol) => {
        setCompany(id);
        setName(name);
        setExchange(exchange || "");
        setSymbol(symbol);
    }

    const selectSource = (id, name, exchange, symbol) => {
        setSource(id);
        setName(name);
        setExchange(exchange || "");
        setSymbol(symbol);
    }

    return (
        <div style={{ margin: "10px auto", width: "1280px" }}>
            <table>
                <thead>
                    <tr>
                        <th style={{ width: "430" }}>Companies</th>
                        <th style={{ width: "420px" }}></th>
                        <th style={{ width: "430px" }}>Source Companies</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td valign="top" style={{ width: "430px" }}>
                            <div style={{ height: "600px", overflow: "auto" }}>
                                {companies.sort((a, b) => a.name.localeCompare(b.name)).map(c => (
                                    <div key={c.id} onClick={() => company && c.id === company ? setCompany("") : selectCompany(c.id, c.name, c.exchange, c.symbol)} style={{ backgroundColor: c.id === company ? "#fcfccc" : "#fff", margin: "2px 0px", padding: "2px", fontSize: "12px", cursor: "pointer" }}>
                                        {c.name}<br/><strong className="font-bold">{c.exchange}:{c.symbol}</strong>
                                    </div>
                                ))}
                            </div>
                        </td>
                        <td valign="top" style={{ width: "420px" }}>
                            { !!errorText && <AlertError text={errorText} />}
                            {!!company && companiesSource.filter(c => c.company_id === company).map(c => (
                                <div key={c.id} style={{ margin: "5px 0px", fontSize: "12px" }}>
                                    {c.name}<br/><strong className="font-bold">{c.exchange}:{c.symbol}</strong> {"("}from {c.source.trim()}{")"}
                                </div>
                            ))}
                            {(!!source || !!company) && (!!name || !!exchange || !!symbol) && (
                                <>
                                    <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 my-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
                                    <input type="text" placeholder="Exchange" value={exchange} onChange={(e) => setExchange(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 my-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
                                    <input type="text" placeholder="Symbol" value={symbol} onChange={(e) => setSymbol(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 my-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
                                </>
                            )}
                            {!!source && !company && !!name && !!exchange && !!symbol && <button onClick={addCompany} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-2 mx-2 rounded">Add</button>}
                            {!!company && !!name && !!exchange && !!symbol && <button onClick={updateCompany} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-2 mx-2 rounded">Update</button>}
                            {!!source && !company && <button onClick={ignoreCompany} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-2 mx-2 rounded">Ignore</button>}
                        </td>
                        <td valign="top" style={{ width: "430px" }}>
                            <div style={{ height: "600px", overflow: "auto" }}>
                                {companiesSource.filter(c => !c.company_id && !c.deleted_at).sort((a, b) => -a.created_at.localeCompare(b.created_at)).map(c => (
                                    <div key={c.id} onClick={() => source && c.id === source ? setSource() : selectSource(c.id, c.name, c.exchange, c.symbol)} style={{ backgroundColor: c.id === source ? "#fcfccc" : "#fff", margin: "2px 0px", padding: "2px", fontSize: "12px", cursor: "pointer" }}>
                                        {c.name}<br/><strong className="font-bold">{c.exchange}:{c.symbol}</strong> {"("}from {c.source.trim()} at {new Date(Date.parse(c.created_at)).toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })}{")"}
                                    </div>
                                ))}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Companies;