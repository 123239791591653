import { useEffect, useState } from 'react';
import { Redirect, useHistory } from "react-router-dom";

import { Link } from 'react-router-dom';

import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";

import AlertError from "../Alerts/Error";

function Signin({ token, signin }) {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleSubmit = (evt) => {
    setErrorText("");
    setSubmitted(true);
    evt.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}/signin`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "email": email,
        "password": password
      })
    })
    .then(r => r.json().then(json => ({ status: r.status, ok: r.ok, body: json })))
    .then(response => {
      if (!response.ok) {
        let err = new Error(`HTTP status code: ${response.status}`);
        err.status = response.status
        throw err
      }
      return response.body
    })
    .then(body => {
      signin(body["username"], body["token"], body["admin"]);
      history.push("/");
    })
    .catch(() => {
      setErrorText("Invalid email or password");
      setSubmitted(false);
    });
  }

  useEffect(() => {
    document.title = 'MiningGrade - Signin';
  }, []);

  if (!!token) return <Redirect to='/' />

  return (
    <div>
      <h1 className="text-2xl font-semibold md:text-4xl">Login</h1>

      {!!errorText ? <AlertError text={errorText} /> : ""}

      <form className="mt-6 w-full md:w-96" onSubmit={handleSubmit}>
        <div>
          <label className="text-sm text-gray-600" htmlFor="email">
            Email
          </label>
          <input
            className="mt-1"
            id="email"
            name="email"
            type="email"
            placeholder="Email address"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className="relative mt-7">
          <label className="text-sm text-gray-600" htmlFor="password">
            Password
          </label>
          <input
            className="mt-1"
            id="password"
            name="password"
            type={passwordShown ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <div className="absolute bottom-4 right-5">
            {passwordShown ? (
              <EyeIcon className="h-4 w-4 hover:text-blue-500" onClick={() => setPasswordShown(false)} />              
            ) : (
              <EyeOffIcon className="h-4 w-4 text-slate-400 hover:text-blue-500" onClick={() => setPasswordShown(true)} />              
            )}
          </div>
        </div>
        <div className="mt-4">
          <Link
            to="/forgot"
            className="text-xs text-gray-600 hover:text-blue-500"
          >
            Forgot Password?
          </Link>
        </div>
        <div className="mt-10">
          <button
            className="w-full rounded-full border border-blue-500 bg-blue-500 px-12 py-2.5 text-lg font-semibold text-white hover:bg-blue-600"
            type="submit"
            disabled={submitted}
          >
            Sign in
          </button>
        </div>
        <div className="mt-10 text-center text-sm text-slate-400">
          Don't have an account yet?
          <Link
            to="/signup"
            className="ml-4 font-semibold text-gray-600 hover:text-blue-500"
          >
            Register for free
          </Link>
        </div>
      </form>
    </div>
  );
}

export default Signin;
