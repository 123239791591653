import { useEffect, useRef, useState } from 'react';

// Infinite scroll helper
export function useIntersectionObserver(ref, options = {}, forward = true) {
  const observer = useRef(null);

  const [element, setElement] = useState(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  const cleanOb = () => {
    if (observer.current) {
      observer.current.disconnect()
    }
  }

  useEffect(() => {
    setElement(ref.current);
  }, [ref]);

  useEffect(() => {
    if (!element) return;

    cleanOb()
    const ob = observer.current = new IntersectionObserver(([entry]) => {
      const isElementIntersecting = entry.isIntersecting;
      if (!forward) {
        setIsIntersecting(isElementIntersecting)
      } else if (forward && !isIntersecting && isElementIntersecting) {
        setIsIntersecting(isElementIntersecting);
        cleanOb()
      };
    }, { ...options })
    ob.observe(element);
    return () => {
      cleanOb()
    }
  }, [element, isIntersecting, options, forward])

  return isIntersecting;
}
